import { FC, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import DoughnutChart, { ChartSize } from '../shared/charts/DoughnutChart';
import { colourRgb } from '../../utils/ColourUtils';
import Tooltip from '../shared/Tooltip';
import { UserStatsActivitiesOption, UserStatsDocumentsOption, UserStatsFilterAllDefault } from '../../pages/home/HomePage';

type ActivitiesChartProps = {
  total: number;
  due: number;
  overdue: number;
  filter: (Omit<(typeof UserStatsFilterAllDefault)[number], 'text'> & { text: string })[];
};

const colours = [colourRgb('accent-2'), colourRgb('accent-4'), colourRgb('gray-6')];

const ActivitiesChart: FC<ActivitiesChartProps> = (props) => {
  const { total, due, overdue, filter } = props;
  const { t } = useTranslation('home-page');

  const data = useMemo(() => [overdue, due, total - due - overdue], [due, overdue, total]);
  const labels = useMemo(
    () => [t('blocks.left.stats.labels.overdue', { count: overdue }), t('blocks.left.stats.labels.due', { count: due }), ''],
    [due, overdue, t],
  );
  const title = useMemo(() => {
    if (!filter.find((x) => x.id === UserStatsDocumentsOption.id)) {
      return t('blocks.left.stats.title.activities');
    }
    if (!filter.find((x) => x.id === UserStatsActivitiesOption.id)) {
      return t('blocks.left.stats.title.documents');
    }
    return t('blocks.left.stats.title.all');
  }, [filter, t]);

  return (
    <div className="flex h-full w-full flex-col items-center justify-between">
      <div className="relative h-48 w-48 justify-self-center">
        <DoughnutChart dataset={data} dataLabels={labels} hideLabels colours={colours} disabled={total === 0} size={ChartSize.SMALL}>
          <Tooltip text={t('blocks.left.stats.title.tooltip', { count: total, title })}>
            {(tooltip) => (
              <div {...tooltip} className="flex h-full flex-col items-center justify-center">
                <div className="text-dpm-20 text-center font-medium">{total}</div>
                <div className="text-dpm-10 whitespace-break-spaces text-center">{title}</div>
              </div>
            )}
          </Tooltip>
        </DoughnutChart>
      </div>
      <div className="text-dpm-12 flex w-full justify-between px-8">
        <div className="flex h-6 items-center justify-center gap-2">
          <div className="bg-accent-4 h-full w-1 rounded-[30px]"></div>
          <Trans t={t} i18nKey={`blocks.left.stats.due.title`} components={{ Bold: <span className="font-medium" /> }} values={{ number: due }} />
        </div>
        <div className="flex h-6 items-center gap-2">
          <div className="bg-accent-2 h-full w-1 rounded-[30px]"></div>
          <Trans
            t={t}
            i18nKey={`blocks.left.stats.overdue.title`}
            components={{ Bold: <span className="font-medium" /> }}
            values={{ number: overdue }}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivitiesChart;
